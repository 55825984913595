import { replace } from '@remix-run/server-runtime'
import GenericErrorBoundary from '#app/components/error-boundary'

export async function loader() {
	// redirect to the last one (most recent)
	return replace(`/app/jobs`)
}

// need the generic error boundary
export const ErrorBoundary = GenericErrorBoundary
